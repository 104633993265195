import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import "../styles/index.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <main className="main">
  <h1>Impressum</h1>
  <h2 id="m46">Diensteanbieter</h2>
  <p>Lele Schlaich.</p>
  <p>Ackerstrasse 22.</p>
  <p>10115 Berlin.</p>
  <h2 id="m56">Kontaktmöglichkeiten</h2>
  <p>
    E-Mail-Adresse: <a href="mailto:lele@czyk.de">lele@czyk.de</a>.
  </p>
  <h2>
    This website is designed by me, and coded in teamwork with <a target="_blank" href="//litc0.de">Basti Paintner</a>.
  </h2>
  <p className="seal">
    <a href="https://datenschutz-generator.de/?l=de" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">
      Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
    </a>
  </p>
  </main>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      